<template>
  <b-form-group
    :label="label">
    <b-form-select
      :ref="`ref${name}`"
      :name="name"
      :size="size"
      :state="verificaEstadoInput()"
      :options="options"
      :disabled="disabled"
      variant="primary"
      v-model="valor"
      @change="onStateInput($event, 'onChange')" />
    <b-form-invalid-feedback>
      {{ retornaMensagemDeErro() }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import { BFormGroup } from "bootstrap-vue"
import { BFormSelect } from "bootstrap-vue"
import { BFormInvalidFeedback } from "bootstrap-vue"
import InputBase from "../inputs/mixins/InputBase"

export default {
  name: "InputSelect",
  components: {
    BFormGroup,
    BFormSelect,
    BFormInvalidFeedback,
  },
  mixins: [ InputBase ],
  props: {
    options: {
      required: false,
    },
  }
};
</script>

<style lang="scss" scoped>
@import '@/common/scss/breakpoints.scss';
@import '@/common/scss/colors.scss';

.option {
  font-size: 0.9rem;
  font-weight: 800;
}

.options li {
    padding:0 6px;
    margin:0 0;
    padding:0 10px;
    color: blueviolet;
}

</style>
