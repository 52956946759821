import axios from 'axios'
import { API_GATEWAY, API_EBS } from './rotasServidor'

export const processaNotaSaida = async (dados) => await axios.post(`${API_GATEWAY}/senior/carregar/nota/saida/completa`, dados)

export const processaNotaEntrada = async (dados) => await axios.post(`${API_GATEWAY}/senior/carregar/nota/entrada/completa`, dados)

export const processaNotaOutrosDoc = async (dados) => await axios.post(`${API_GATEWAY}/senior/carregar/nota/outros/completa`, dados)

export const processaNotaTodas = async (dados) => await axios.post(`${API_GATEWAY}/senior/carregar/nota/todas`, dados)

export const processaAtualizarNotaSaida = async (dados) => await axios.post(`${API_GATEWAY}/senior/atualizar/nota/saida`, dados)

export const listarNotasTodas = async (dados) => await axios.post(`${API_GATEWAY}/senior/listar/notas/todas`, dados)

export const atualizarNota = async (dados) => await axios.post(`${API_GATEWAY}/senior/atualizar/notas`, dados)

export const getListaProcessamentos = async (quantidade) => await axios.get(`${API_GATEWAY}/senior/listar/notas/${quantidade||5}`)

export const getListaNotas = async (quantidade, filtro) => await axios.get(`${API_GATEWAY}/integracao/visao/lista/notas/${quantidade||1}?f=${JSON.stringify(filtro)}`)

export const getXMLNota = async (nota) => await axios.post(`${API_GATEWAY}/integracao/visao/xml/nota`, nota)

export const reprocessarNota = async (nota) => await axios.post(`${API_GATEWAY}/integracao/notas`, nota)

export const reprocessarNotaXml = async (nota) => await axios.post(`${API_GATEWAY}/integracao/notas/xml`, nota)

export const inutilizarNota = async (xml) => await axios.post(`${API_GATEWAY}/integracao/inutilizacao/nota`, xml)

export const solicitarResposta = async (nota) => await axios.post(`${API_GATEWAY}/integracao/altera/status/nota`, nota)