<template>
    <div>
      <loading 
        :active.sync="dados.isLoading" 
        :can-cancel="false" 
        :is-full-page="true"
        color="#f68b29"/>
      <b-form
      class="filtro"
      @submit.prevent="processarNota(dados.tipoNota)">
            
          <InputSelect
            ref="refTiposNotas"
            label="Tipo de documento"
            name="nota"
            :options="getTiposNota()"
            class="inputTiposNotas"
            v-model="dados.tipoNota"
            :disabled="dados.disabledCampos || dados.checkedStatus"
            v-validate="{ required:true}"
            :errorsBag="errors"
          />
          <InputBasico
            ref="refDataInicial"
            label="Data inicial"
            name="dataInicial"
            v-model="dados.dataInicial"
            type="date"
            min=1
            max=12
            class="inputDataInicial"
            v-if="dados.visivel"
            :disabled="dados.disabledCampos || dados.checkedDocumento || dados.checkedNota"
            v-validate="{ required:!dados.checked}"
            :errorsBag="errors"
          />
          <InputBasico
            ref="refDataFinal"
            label="Data final"
            name="dataFinal"
            v-model="dados.dataFinal"
            type="date"
            min=1
            max=12
            class="inputDataFinal"
            v-if="dados.visivel"
            :disabled="dados.disabledCampos || dados.checkedDocumento || dados.checkedNota"
            v-validate="{ required:!dados.checked}"
            :errorsBag="errors"
          />
          <InputSelect
            ref="refFilial"
            label="Código da filial"
            name="filial"
            :options="getFiliais()"
            :class="dados.visivel ? 'inputFilial':'inputDataInicial'"
            v-model="dados.filialEmpresa"
            :disabled="dados.disabledCampos || dados.checkedDocumento || dados.checkedNota"
          />
          <div class="notaCard">
            <b-form-checkbox  v-model="dados.checkedStatus" name="check-button" :disabled="dados.disabledCampos" @change="marcouCheckStatus"  switch>
              Somente atualização do status das notas de saída canceladas
            </b-form-checkbox>
          </div>
          <div class="notaCard">
              <!-- <b-button v-b-toggle.collapse-1 variant="primary">Toggle Collapse</b-button> -->
              <b-form-checkbox  v-model="dados.checkedDocumento" name="check-button" :disabled="dados.disabledCampos" switch>
                  Escrituração por (ID) número do documento
              </b-form-checkbox>
              <b-collapse id="collapse-1" v-model="dados.checkedDocumento">
                  <InputBasico
                  ref="refDocumento"
                  label="(ID) Número do documento"
                  name="docNum"
                  v-model="dados.docNum"
                  info="Para inserir IDs múltiplos, eles deverão estar separados por vírgula(,)."
                  type="text"
                  class="inputDocnum"
                  :disabled="dados.disabledCampos"
                  v-validate="{ required:dados.checkedDocumento}"
                  :errorsBag="errors"
                  />
              </b-collapse>
          </div>
          <div class="notaCard">
              <b-form-checkbox  v-model="dados.checkedNota" name="check-button" :disabled="dados.disabledCampos" switch>
                  Escrituração por número e série da nota
              </b-form-checkbox>
              <b-collapse id="collapse-1" v-model="dados.checkedNota">
                  <InputBasico
                  ref="refDocumento"
                  label="Série"
                  name="serie"
                  v-model="dados.serie"
                  type="text"
                  class="inputSerieNota"
                  :disabled="dados.disabledCampos"
                  v-validate="{ required:dados.checkedNota}"
                  :errorsBag="errors"
                  />
                  <InputBasico
                  ref="refDocumento"
                  label="Número da nota"
                  name="docNum"
                  v-model="dados.numeroNota"
                  type="text"
                  class="inputNumeroNota"
                  :disabled="dados.disabledCampos"
                  v-validate="{ required:dados.checkedNota}"
                  :errorsBag="errors"
                  />
              </b-collapse>
          </div>

          <b-button type="submit" variant="primary" class="btnProcessar" :disabled="dados.disabledCampos">Processar</b-button>
          <label v-if="dados.isConcluido" class="concluido loaderCheck">Concluído com sucesso!</label>
          
          <div class="processarCard">
            <b-card no-body >
              <b-card-header header-tag="header" class="p-1" role="tab">
                Itens para processar
              </b-card-header>
              
                <b-card-body>
                  <b-form-checkbox-group
                    id="checkbox-group-1"
                    v-model="dados.selecionados"
                    :options="options"
                    name="flavour-1"
                    :disabled="dados.checkedStatus"
                  ></b-form-checkbox-group>
                </b-card-body>
            </b-card>
          </div>

          <div class="loader" v-if="dados.show_loader_erp">
            <b-spinner v-if="!dados.checked_erp" class="text-center justify-content-between" variant="warning" label="Loading..."></b-spinner>
            <v-icon v-if="dados.checked_erp" scale="2" name="check" class="loaderCheck"/>
            <label class="concluido">Carregando dados ERP...</label>
          </div>
          <div class="loader" v-if="dados.show_loader_produto">
            <b-spinner v-if="!dados.checked_produto" class="text-center justify-content-between" variant="warning" label="Loading..."></b-spinner>
            <v-icon v-if="dados.checked_produto" scale="2" name="check" class="loaderCheck"/>
            <label class="concluido">Carregando produtos...</label>
          </div>
          <div class="loader" v-if="dados.show_loader_cliente">
            <b-spinner v-if="!dados.checked_cliente" class="text-center justify-content-between" variant="warning" label="Loading..."></b-spinner>
            <v-icon v-if="dados.checked_cliente" scale="2" name="check" class="loaderCheck"/>
            <label class="concluido">Carregando clientes...</label>
          </div>
          <div class="loader" v-if="dados.show_loader_fornecedor">
            <b-spinner v-if="!dados.checked_fornecedor" class="text-center justify-content-between" variant="warning" label="Loading..."></b-spinner>
            <v-icon v-if="dados.checked_fornecedor" scale="2" name="check" class="loaderCheck"/>
            <label class="concluido">Carregando fornecedores...</label>
          </div>
          <div class="loader" v-if="dados.show_loader_nota">
            <b-spinner v-if="!dados.checked_nota" class="text-center justify-content-between" variant="warning" label="Loading..."></b-spinner>
            <v-icon v-if="dados.checked_nota" scale="2" name="check" class="loaderCheck"/>
            <label class="concluido">Processando as notas...</label>
          </div>

          <!-- <div role="tablist" class="listaIntegracoes" :key="this.dados.key">
            <b-card no-body class="mb-1" v-for="item in dados.listaProcessamentos" :key="item._id">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-1 variant="light">{{retornaDataFormatadaTabelaConsulta(item.dataInicioProcessamento)}} | {{item.parametros.tipo}}</b-button>
              </b-card-header>
              <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <template>
                    <h1>Parametros</h1>
                    
                  </template>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div> -->

          <div role="tablist" class="listaIntegracoes" v-if="dados.itemProcessamento && dados.itemProcessamento.parametros">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button v-if="dados.itemProcessamento && dados.itemProcessamento.parametros" block v-b-toggle.accordion-1 variant="light">{{retornaDataFormatadaTabelaConsulta(dados.itemProcessamento.dataInicioProcessamento)}} | {{dados.itemProcessamento.parametros.tipo}}</b-button>
              </b-card-header>
              <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                <b-card-body  v-if="dados.itemProcessamento && dados.itemProcessamento.parametros">
                  
                  <template>
                    <p class="parametrizacao bold">Parametros</p>
                    <p class="parametrizacao">Tipo de documento: {{dados.itemProcessamento.parametros.tipo}}</p>
                    <p class="parametrizacao">Data inicial: {{retornaDataFormatadaDiaMesAno(dados.itemProcessamento.parametros.date_low)}}</p>
                    <p class="parametrizacao">Data final: {{retornaDataFormatadaDiaMesAno(dados.itemProcessamento.parametros.date_high)}}</p>
                    <p class="parametrizacao">Código da filial: {{dados.itemProcessamento.parametros.branch}}</p>
                    <b-button-group>
                      <b-button v-b-modal.produtos variant="info">Produtos</b-button>
                      <b-button v-b-modal.participantes variant="warning">Participantes</b-button>
                    </b-button-group>
                    <div>
                      <b-table 
                        striped 
                        hover 
                        bordered 
                        head-variant="dark"
                        stacked="md"
                        v-if="dados.itemProcessamento" 
                        :items="dados.itemProcessamento.retornoNota" 
                        :fields="dados.fieldsNotas">
                        <template v-slot:cell(notaERP)="data">
                          <b-button variant="info" v-b-modal.notaERP @click="abrirNotaERP(data.item.nota)"><v-icon scale="1" name="eye"/></b-button>
                        </template>
                        <template v-slot:cell(notaSapiens)="data">
                          <b-button variant="warning" v-b-modal.notaEnvio @click="abrirEnvioSapiens(data.item.requisicaoSapiens)"><v-icon scale="1" name="eye"/></b-button>
                        </template>
                      </b-table>
                    </div>
                  </template>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>

          <b-modal id="produtos" title="Produtos" size="xl">
            <div>
              <b-table striped hover bordered head-variant="dark" v-if="dados.itemProcessamento" :items="dados.itemProcessamento.retornoProduto" :fields="dados.fieldsProdutos"></b-table>
            </div>
          </b-modal>
          <b-modal id="participantes" title="Participantes" size="xl">
            <div>
              <b-table striped hover bordered head-variant="dark" v-if="dados.itemProcessamento" :items="dados.itemProcessamento.retornosCliente" :fields="dados.fieldsParticipantes"></b-table>
            </div>
          </b-modal>

          <b-modal 
            id="notaERP" 
            title="Nota ERP" 
            size="xl">
            <div>
              <vue-json-pretty
                :showLine="true"
                :showDoubleQuotes="true"
                :highlightMouseoverNode="true"
                :collapsedOnClickBrackets="true"
                :data="dados.notaERPView">
              </vue-json-pretty>
            </div>
          </b-modal>

          <b-modal 
            id="notaEnvio" 
            title="Estrutura de envio" 
            size="xl">
            <div>
              <vue-json-pretty
                :showLine="true"
                :showDoubleQuotes="true"
                :highlightMouseoverNode="true"
                :collapsedOnClickBrackets="true"
                :data="dados.notaEnvioView">
              </vue-json-pretty>
            </div>
          </b-modal>
      </b-form>
    </div>
</template>

<script>
import {processaNotaSaida,
        processaNotaEntrada,
        processaNotaTodas,
        getListaProcessamentos} from "../../../common/services/notasService";

import * as dataHelper from '@/common/helpers/DataHelper'
import { getUserObj } from '@/common/localStorage/localStorage'

import InputBasico from "@/components/inputs/InputBasico";
import InputSelect from "@/components/inputs/InputSelect";
import VueJsonPretty from 'vue-json-pretty'

export default {
  data() {
    return {
      dados: {
        dataInicial: '',
        dataFinal: '',
        docNum: '',
        checkedNota: false,
        checkedDocumento: false,
        checkedStatus: false,
        checked_erp: false,
        checked_fornecedor: false,
        checked_cliente: false,
        checked_produto: false,
        checked_nota: false,
        show_loader_erp: false,
        show_loader_fornecedor: false,
        show_loader_cliente: false,
        show_loader_produto: false,
        show_loader_nota: false,
        isConcluido: false,
        filialEmpresa: '',
        tipoNota: '',
        disabledCampos: false,
        itemProcessamento: null,
        visivel: getUserObj().erp.tipo && getUserObj().erp.tipo != 'INFORLN',
        selecionados: [
          'materiais',
          'participantes',
          'notas'
        ],
        key: false,
        isLoading: true,
        fieldsProdutos: [
          { key: "codEmp", label: "Código empresa", sortable: true },
          { key: "codPro", label: "Código produto", sortable: true },
          { key: "desRet", label: "Descrição retorno", sortable: true }
        ],
        fieldsParticipantes: [
          { key: "cgcCpf", label: "CPF/CNPJ", sortable: true },
          { key: "codCli", label: "Código cliente", sortable: true },
          { key: "codFor", label: "Código de fornecedor", sortable: true },
          { key: "ideExt", label: "Identificação externa", sortable: true },
          { key: "retorno", label: "Descrição retorno", sortable: true }
        ],
        fieldsNotas: [
          { key: "retornoImportacao.result.gridRetorno.codEmp", label: "Código empresa", sortable: true },
          { key: "retornoImportacao.result.gridRetorno.codFil", label: "Código filial", sortable: true },
          { key: "retornoImportacao.result.gridRetorno.codEmp", label: "codSnf", sortable: true },
          { key: "retornoImportacao.result.gridRetorno.codTns", label: "CFOP", sortable: true },
          { key: "retornoImportacao.result.gridRetorno.msgRet", label: "Mensagem retorno", sortable: true },
          { key: "retornoImportacao.result.gridRetorno.numNff", label: "Sequencia", sortable: true },
          { key: "retornoImportacao.result.gridRetorno.numNfi", label: "Número da nota", sortable: true },
          { key: "notaERP", label: "Nota ERP", sortable: true },
          { key: "notaSapiens", label: "Nota envio Sapiens", sortable: true },
        ],
        items: [
          { codEmp: '1', codPro: '1997', desRet: 'OK' },
          { codEmp: '1', codPro: '1998', desRet: 'OK' },
          { codEmp: '1', codPro: '1999', desRet: 'OK' },
          { codEmp: '1', codPro: '2000', desRet: 'OK' },
          { codEmp: '1', codPro: '2001', desRet: 'OK' },
          { codEmp: '1', codPro: '2002', desRet: 'OK' },
          { codEmp: '1', codPro: '2003', desRet: 'OK' },
          { codEmp: '1', codPro: '2004', desRet: 'OK' },
          { codEmp: '1', codPro: '2005', desRet: 'OK' },
          { codEmp: '1', codPro: '2006', desRet: 'OK' },
          { codEmp: '1', codPro: '2007', desRet: 'OK' },
          { codEmp: '1', codPro: '2008', desRet: 'OK' },
          { codEmp: '1', codPro: '2009', desRet: 'OK' },
          { codEmp: '1', codPro: '2010', desRet: 'OK' },
          { codEmp: '1', codPro: '2011', desRet: 'OK' },
          { codEmp: '1', codPro: '2012', desRet: 'OK' },
          { codEmp: '1', codPro: '2013', desRet: 'OK' },
          { codEmp: '1', codPro: '2014', desRet: 'OK' },
          { codEmp: '1', codPro: '2015', desRet: 'OK' },
          { codEmp: '1', codPro: '2016', desRet: 'OK' },
          { codEmp: '1', codPro: '2017', desRet: 'OK' }
        ],
        showModalNota: false,
        showModalEnvioSapiens: false,
        notaERPView: {},
        notaEnvioView: {},
      },
      options: [
          { text: 'Materiais', value: 'materiais' },
          { text: 'Participantes', value: 'participantes' },
          { text: 'Notas', value: 'notas' }
      ]
    }
  },
  components: {
    InputBasico,
    InputSelect,
    VueJsonPretty
  },
  created: async function() {
    await this.getListaProcessamentos()

    if (!this.dados.visivel) {
      this.dados.dataInicial = dataHelper.atualDateYYYYMMDD('-');
      this.dados.dataFinal = dataHelper.atualDateYYYYMMDD('-');
    }
  },
  sockets: {
    ALTERAR_FORM_NOTA: async function (data) {
      this.dados.disabledCampos = true
      Object.keys(data).forEach(key => {
        this.dados[key] = data[key]
      });
    },
    HABILITAR_FORM_NOTA: async function (data) {
      this.dados.disabledCampos = false
    },
    RETORNO_PROCESSAMENTO_FORM_NOTA: async function (data) {
      this.dados.itemProcessamento = data
      this.$validator.reset()
      this.errors.clear()
    }
  },
  methods: {
    getTiposNota() {
      return [
        { text: 'Todas', value: "TODAS"},
        { text: 'Entrada', value: "ENTRADA"},
        { text: 'Saída', value: "SAIDA"},
        { text: 'Outros documentos - 3', value: "OUTROS3"},
        { text: 'Outros documentos - 4', value: "OUTROS4"},
      ]
    },
    getFiliais() {
      let filiais = [ 
          {
            "codFilial" : "Todas",
            "codFil" : "Todas",
            "codDep" : "TODAS",
            "codFiltro" : "",
            "status" : true
          }
      ]
      filiais.push(...getUserObj().filiais)
      return filiais.map(item => {
        return { text: `${item.codFil}`, value: `${item.codFiltro}` }
      })
    },
    montaDadosEnvio(tp_doc){
      let retorno = {
        tipo: this.dados.tipoNota,
        date_low: this.dados.dataInicial,
        date_high: this.dados.dataFinal,
        branch: this.dados.filialEmpresa,
        entrada_saida: tp_doc,
        listaProcessar: this.dados.selecionados
      }

      if (tp_doc == "0") {
        delete retorno.entrada_saida;
      }

      if (this.dados.checkedStatus) {
        retorno.tipo = "SAIDA";
        retorno.entrada_saida = "2";
        retorno.apenasStatusCancelada = true;
        retorno.listaProcessar = ['notas'];
      }

      if(this.dados.docNum != "" && this.dados.checkedDocumento){
        if (this.dados.docNum.indexOf(',') > 0) {
          let temp = this.dados.docNum.split(',');
          retorno.listaDocnum = [];
          Object.values(temp).forEach(e => {
            if (e)
              retorno.listaDocnum.push({docnum: e});            
          });
        } else {
          retorno.listaDocnum = [
            {docnum: this.dados.docNum}
          ]
        }
      }
      return retorno
    },
    marcouCheckStatus(checked) {
      if (checked) {
        this.dados.tipoNota = "SAIDA";
      } else {
        this.dados.tipoNota = "TODAS";
      }
    },
    async processarNota(tipo){
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          console.log(this.montaDadosEnvio())
          // if()
          if(tipo == "SAIDA"){
            try {
              let ret = await processaNotaSaida(this.montaDadosEnvio(2))
              // console.log(ret)
            } catch (error) {
              console.error(error)
            }
          }else if(tipo == "ENTRADA"){
            try {
              let ret = await processaNotaEntrada(this.montaDadosEnvio(1))
              // console.log(ret)
            } catch (error) {
              console.error(error)
            }
          }else if(tipo == "OUTROS3"){
            try {
              let ret = await processaNotaOutrosDoc(this.montaDadosEnvio(3))
              // console.log(ret)
            } catch (error) {
              console.error(error)
            }
          }else if(tipo == "OUTROS4"){
            try {
              let ret = await processaNotaOutrosDoc(this.montaDadosEnvio(4))
              // console.log(ret)
            } catch (error) {
              console.error(error)
            }
          } else if(tipo == "TODAS"){
            try {
              let ret = await processaNotaTodas(this.montaDadosEnvio(0))
              // console.log(ret)
            } catch (error) {
              console.error(error)
            }
          }
        }
      });
      
    },
    retornaDataFormatadaTabelaConsulta(data) {
      // console.log(data)
      return dataHelper.retornaISOParaStringComMascara(data, 'DD/MM/YYYY - HH:mm:ss')
    },
    retornaDataFormatadaDiaMesAno(data) {
      // console.log(data)
      return dataHelper.retornaISOParaStringComMascara(data, 'DD/MM/YYYY')
    },
    async getListaProcessamentos() {
      let r = (await getListaProcessamentos(1)).data
      this.dados.itemProcessamento = r[0]
      // console.log(this.dados.itemProcessamento)
      this.dados.isLoading = false
      this.key = !this.key
    },
    abrirNotaERP(nota){
      // console.log('a',nota)
      // this.showModalNota = true
      this.dados.notaERPView = nota
    },
    abrirEnvioSapiens(envio){
      // console.log('a',nota)
      // this.showModalNota = true
      this.dados.notaEnvioView = envio
    }
  }
  
}
</script>

<style lang="scss" scoped>
@import "@/common/scss/breakpoints.scss";

.filtro {
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: $medium-size) {
    width: 100%;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 0.8em;
  }

//   @media (min-width: $extra-large-size) {
//     width: $medium-size;
//   }
}

.input {
  &TiposNotas {
    @media (min-width: $medium-size) {
      grid-column: 1 / 4;
    }
  }

  &DataInicial {
    @media (min-width: $medium-size) {
      grid-column: 1 / 4;
    }
  }

  &DataFinal {
    @media (min-width: $medium-size) {
      grid-column: 4 / 7;
    }
  }
  &Filial {
    @media (min-width: $medium-size) {
      grid-column: 7 / 10;
    }
  }
  &Docnum {
    @media (min-width: $medium-size) {
      grid-column: 1 / 4;
    }
  }
}

.btn {
    &Processar {
        @media (min-width: $medium-size) {
            grid-column: 1 / 4;
            margin-top: 1.9rem;
            margin-bottom: 1.9rem;
        }
    }
}

.listaIntegracoes {
    @media (min-width: $medium-size) {
        grid-column: 1 / -1;
    }
}
.concluido {
    @media (min-width: $medium-size) {
        grid-column: 4 / -1;
        align-self: center;
        margin-left: 1.5rem;
    }
}

.loader {
    @media (min-width: $medium-size) {
        grid-column: 1 / -1;
        align-self: center;
    }
    &Spinner {
        align-self: center;
    }
    &Check {
        color: #00cf2d;
    }
}
.notaCard {
    @media (min-width: $medium-size) {
        grid-column: 1 / 10;
        padding-bottom: 1.5rem;
    }
}
.processarCard {
    @media (min-width: $medium-size) {
        grid-column: 1 / 6;
        padding-bottom: 1.5rem;
    }
}

.parametrizacao {
  margin: 0;
}
</style>