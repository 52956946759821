<template>
  <LayoutTelas id="tela__notas" :titulo="this.$route.meta.titulo">
    <div class="about">
      <!-- <p>Esta é a página do Inventário</p> -->
    </div>
    <NotaForm/>
    
  </LayoutTelas>
  
</template>

<script>
import NotaForm from "./forms/NotaForm";

export default {
  data() {
    return {
      dataInventario: '',
    }
  },
  components: {
    NotaForm
  }
  
}
</script>

<style lang="scss" scoped>
@import "@/common/scss/breakpoints.scss";

#tela__notas {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  @media (min-width: $medium-size) {
    min-width: $small-size;
  }
}

.paginacao {
  display: flex;
  justify-content: flex-end;

  &__seletor {
    margin: 0;
  }
}

.mesInventario {
  
}

.anoInventario {

}
</style>